[data-v-24570d53] .ht_clone_left {
  z-index: 1;
}
[data-v-24570d53] .ht_clone_top {
  z-index: 1;
}
[data-v-24570d53] .ht_clone_top_inline_start_corner.ht_clone_top_left_corner {
  z-index: 1;
}
[data-v-24570d53] .ht_master {
  padding-bottom: 15px;
}
[data-v-24570d53] .ht_clone_inline_start.ht_clone_left.handsontable {
  display: inline;
}
